body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
      rgba(30, 30, 30, 0.4),
      rgba(30,30, 30, 0.4)
      ), url("./images/adventure.png");
  background-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300&family=Righteous&display=swap');

// REGISTRATION AND LOGIN STYLES

.register-background-div {
  display: flex;
  justify-content: center;
  font-family: 'Hind Madurai', sans-serif;
  margin: 0px;
  .register-div {
    background-color: white;
    width: 45rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: calc(50% - 15rem)
  }
  .register-title {
    font-size: 2.5rem;
  }
  .auth-form {
    width: 40rem;
    height: 20rem;
    background-color: white;
    .form-label {
      color: #8b8b8b;
      font-weight: 500;
    }
    .form-input {
      width: 100%;
      border: 1px solid rgb(189, 189, 189);
      padding: .5rem 0 .5rem 0;
      margin-bottom: 1rem;
    }
    .form-button {
      width: 100%;
      font-size: 1.2rem;
      padding: .5rem 0 .5rem 0;
      background-color: #101146;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .already-div {
    display: flex;
    font-size: 1rem;
    height: 1.5rem;
    justify-content: flex-start;
    width: 40rem;
    margin: 1rem 0 1rem 0;
    p {
      color: #8b8b8b;
      height: 1rem;
      margin: 0 5px 0 0 ;
    }
    .text-blue {
      text-decoration: none;
      color: #0004ff;
    }
  }
}

// CONTROLLER STYLES

$control-width: 150px;
$arrow-width: 50px;

.control-div {
  width: $control-width;
  height: $control-width;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  border-radius: 50%;
  background-color: rgb(206, 206, 206);
  .blank-div {
    width: $arrow-width;
    height: $arrow-width;
  }
  .arrow-img {
    width: $arrow-width;
    height: $arrow-width;
    cursor: pointer;
  }
}

// NAVBAR STYLES

.nav-div {
  background-color: rgb(33, 33, 44);
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Righteous', cursive;
  padding: .5rem 1rem;
  color: white;
  .nav-left {
    display: flex;
    align-items: center;
    height: 4rem;
    img {
      width: 4rem;
      height: 4rem;
    }
    h1 {
      margin-left: 1rem;
    }
  }
  .logout {
    cursor: pointer;
  }
  
}

// GAME PAGE STYLES

.game-page {
  display: flex;
  padding: 2rem;
  justify-content: center;
  .map-panel {
    width: 50rem;
    height: 50rem;
    background-image : url("./images/paper.jpg");
    background-size: 100% 100%;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(33, 33, 44);
  }
  .xy-plot {
    margin: 2rem 2rem 0 0;
  }
  .right-panels {
    font-family: 'Hind Madurai', sans-serif;
    width: 50rem;
    height: 50rem;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    .info-panel {
      color: white;
      width: 50rem;
      min-height: 30rem;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 2rem;
      border-radius: .5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .h-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        * {
          margin-right: .6rem;
        }
      }
    }
  }
}